* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f9fafb;
  font-family: "Roboto", sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.grid-container {
  display: inline-grid;
  /* grid-template-columns: 0.66fr 1fr; */
  grid-template-columns: auto auto;
  grid-gap: 25px;

  align-items: flex-start;
}

.income-table {
  margin-top: 10rem;
  margin-left: 1rem;
  min-width: 750px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  user-select: none;
}

.expenditure-table {
  margin-top: 10rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-width: 1070px;
  user-select: none;
}

.header {
  text-align: center;
  margin-bottom: 1rem;
  padding-top: 10px;
}

.input-fields {
  width: 15rem;
  margin-left: 3rem;
  padding: 4px;
  font-size: 20px;
  user-select: none;
  text-align: center;
  text-transform: capitalize;
}
.no-fields {
  width: 105px;
  margin: 1rem;
}

.btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.btn-download-box {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.btn {
  padding: 10px;
  width: 150px;
  border: none;
  border-radius: 4px;
  background-color: #10b981;
  color: white;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
}

.btn:hover {
  background-color: #10b981a3;
}

.column-heading {
  font-size: 25px;
  font-weight: bold;
  user-select: none;
  position: relative;
}

.date {
  left: 25.5rem;
}

.amount {
  left: 21.7rem;
}

.flat {
  left: 17rem;
}

.name {
  left: 8rem;
}

.number {
  left: 1.4rem;
}

.btn-delete {
  padding: 4px;
  color: red;
  cursor: pointer;
}

.text-area-field {
  width: 16rem;
  height: 2rem;
  font-size: 20px;
  margin: 1rem;
  padding: 4px;
  text-align: center;
  resize: none;
  overflow: hidden;
  text-transform: capitalize;
}

.textarea-no-fields {
  width: 120px;
}

.expReason {
  left: 28.5rem;
}

.expAmount {
  left: 21rem;
}

.expDate {
  left: 15.5rem;
}

.vrNo {
  left: 10rem;
}

.expName {
  left: 4rem;
}

/* .text-area-btn-delete {
  padding: 4px;
  color: red;
} */

.month-input-box {
  text-align: center;
  position: relative;
  /* right: 12rem; */
  margin: 0 auto;
  user-select: none;
}

.monthLabel {
  font-size: 3rem;
}

.month-input {
  padding: 5px;
  margin-left: 6px;
  font-size: 1.5rem;
  user-select: none;
}

.income-total {
  position: relative;
  left: 22.7rem;
}

.income-total-label {
  position: relative;
  left: 23rem;
}

.header-container {
  margin-top: 2rem;
}

.CF {
  position: relative;
  left: 23.3rem;
}

.expense-total-label {
  position: relative;
  left: 33rem;
}

.next-month-cf-label {
  position: relative;
  left: 26.5rem;
}

.expense-total {
  position: relative;
  left: 33rem;
}

.next-month-cf {
  position: relative;
  left: 26.3rem;
}

.exp-name,
.exp-reason {
  position: relative;
  top: 1.6rem;
}
.btn-submit-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-submit {
  background-color: #4b86de;
  font-size: 20px;
  color: white;
  width: 150px;
  padding: 10px;
  border: none;
  margin: 1rem;
  border-radius: 6px;
  cursor: pointer;
}

.btn-submit:hover {
  background-color: #4b86dead;
}

.flex-container {
  display: flex;
  height: auto;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  margin-top: 12rem;
  user-select: none;
}

.login {
  line-height: 50px;
  border-bottom: #10b981 1px solid;
}

.form {
  margin-top: 10px;
  padding: 50px;
  width: fit-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.signin-label {
  font-size: 20px;
  font-weight: bold;
  color: rgb(36, 35, 35);
}

.signin-input {
  padding: 10px;
  font-size: 20px;
  margin-top: 3px;
  width: 350px;
  margin-bottom: 20px;
  outline: none;
  border: none;
  border-bottom: #10b981 1px solid;
  background-color: #f9fafb;
}

.eye {
  position: relative;
  right: 1.3rem;
}

.exist-new-user {
  text-decoration: none;
  text-align: center;
}

.exist-new-user:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1200px) {
  body {
    font-size: 15px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: max-content;
    grid-gap: 5px;
  }

  .input-fields {
    font-size: 20px;
    width: 17rem;
    margin: 13px;
    margin-left: 7rem;
  }
  .no-fields {
    width: 110px;
    margin: 1rem;
  }

  .column-heading {
    font-size: 25px;
  }
  .name {
    left: 13.5rem;
  }
  .flat {
    left: 23.5rem;
  }
  .amount {
    left: 28.7rem;
  }
  .date {
    left: 33rem;
  }

  .CF {
    left: 30.5rem;
  }

  .income-total {
    left: 29.7rem;
  }

  .income-total-label {
    left: 30rem;
  }

  .btn {
    font-size: 20px;
    padding: 10px;
    width: 7rem;
  }

  .expenditure-table {
    margin-top: 5rem;
  }

  .text-area-field {
    font-size: 20px;
    width: 10rem;
    margin: 10px;
    margin-left: 4rem;
  }
  .textarea-no-fields {
    width: 120px;
  }
  .expName {
    left: 4rem;
  }
  .vrNo {
    left: 10rem;
  }
  .expDate {
    left: 18rem;
  }
  .expAmount {
    left: 25.5rem;
  }
  .expReason {
    left: 33.5rem;
  }
  .expense-total-label {
    left: 38rem;
  }
  .expense-total {
    left: 37.5rem;
  }
  .next-month-cf {
    left: 30.5rem;
  }
  .next-month-cf-label {
    left: 31rem;
  }
  .btn-submit {
    font-size: 20px;
    width: 7rem;
    padding: 7px;
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 13px;
  }

  .month-input-box {
    position: relative;
    margin-top: 10px;
  }

  .month-input {
    position: absolute;
  }
  .monthLabel {
    line-height: 0.8;
  }

  .btn-download-box,
  .btn-submit-box {
    position: relative;
  }
  .btn-submit {
    position: absolute;
    top: 0px;
    left: 30rem;
  }

  .btn-download-box > .btn {
    position: absolute;
    top: 5rem;
    left: 31rem;
  }

  .grid-container {
    display: grid;
    grid-template-columns: max-content;
    grid-gap: 5px;
  }

  .input-fields {
    font-size: 15px;
    width: 15rem;
    margin: 13px;
    margin-left: 7rem;
  }
  .no-fields {
    width: 80px;
    margin: 1rem;
  }

  .column-heading {
    font-size: 20px;
  }
  .name {
    left: 13rem;
  }
  .flat {
    left: 22rem;
  }
  .amount {
    left: 25.5rem;
  }
  .date {
    left: 29rem;
  }

  .CF {
    left: 27rem;
  }

  .income-total {
    left: 27rem;
  }

  .income-total-label {
    left: 27rem;
  }

  .btn {
    font-size: 18px;
    padding: 5px;
    width: 6rem;
  }

  .expenditure-table {
    margin-top: 5rem;
  }

  .text-area-field {
    font-size: 15px;
    width: 130px;
    margin: 10px;
    margin-left: 5rem;
  }
  .textarea-no-fields {
    width: 80px;
  }
  .expName {
    left: 5rem;
  }
  .vrNo {
    left: 11rem;
  }
  .expDate {
    left: 18rem;
  }
  .expAmount {
    left: 25.5rem;
  }
  .expReason {
    left: 33rem;
  }
  .expense-total-label {
    left: 36rem;
  }
  .expense-total {
    left: 35.7rem;
  }
  .next-month-cf {
    left: 30.5rem;
  }
  .next-month-cf-label {
    left: 31rem;
  }
  .btn-submit {
    font-size: 20px;
    width: 6rem;
    padding: 6px;
  }
}

@media screen and (max-width: 500px) {
  .input-fields {
    margin-left: 5rem;
  }
  .text-area-field {
    margin-left: 4rem;
  }

  .name {
    left: 10rem;
  }
  .flat {
    left: 24rem;
  }
  .amount {
    left: 31.5rem;
  }
  .date {
    left: 39rem;
  }
  .CF {
    left: 29rem;
  }
  .income-total {
    left: 29rem;
  }
  .income-total-label {
    left: 32rem;
  }
  .next-month-cf-label {
    left: 26.5rem;
  }
  .expense-total-label {
    left: 32rem;
  }
  .expense-total {
    left: 27.5rem;
  }
  .next-month-cf {
    left: 22rem;
  }
  .expName {
    left: 4rem;
  }
  .vrNo {
    left: 9rem;
  }
  .expDate {
    left: 15.5rem;
  }
  .expAmount {
    left: 21.5rem;
  }
  .expReason {
    left: 28rem;
  }
}

@media screen and (max-width: 464px) {
  .month-input-box {
    position: relative;
    margin-top: 10px;
  }
  .month-input {
    position: absolute;
    left: 25rem;
  }
  .monthLabel {
    position: absolute;
    font-size: 50px;
    line-height: 0.9;
  }

  .signin-input {
    width: 270px;
  }
  .form {
    position: relative;
  }
  .eye {
    position: absolute;
    top: 12rem;
    left: 19rem;
  }

  .btn-submit-box,
  .btn-download-box {
    position: relative;
    margin: 1rem;
  }
  .btn-submit {
    position: absolute;
    top: 0px;
    left: 29rem;
  }
  .btn-download-box > .btn {
    position: absolute;
    top: 3rem;
    left: 30rem;
  }
}
